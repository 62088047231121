$(document).ready(function(){
    $('[id$=quantity]').keyup(function() {

        if($(this).parent().parent().find('strong').length == 0) {
            $(this).parent().after('<strong class="mt-2 d-block"></strong>');
        }
        $(this).parent().parent().find('strong').html((this.value / 1000).toFixed(3) + ' kg');
    });

    $( "[id$=quantity]" ).each(function( index ) {
        if($(this).parent().parent().find('strong').length == 0) {
            $(this).parent().after('<strong class="mt-2 d-block"></strong>');
        }
        $(this).parent().parent().find('strong').html((this.value / 1000).toFixed(3) + ' kg');
    });
});