// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import "@zxing/library"
import "./controllers"
import "./quantity-check.js"

// import "app"
import * as bootstrap from "bootstrap"
import Rails from "@rails/ujs"
Rails.start()

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
})